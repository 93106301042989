.App {
  text-align: center;
}

/******Modal*****/

.modal-content {
  width: 300px;
  height: 400px;
}

.modal-header {
  padding: 0px;
}

.close {
  padding: 5px !important;
  margin: 0px !important;
}

.modal-dialog{
  width: 300px !important;
  margin: 0px !important;
  left: calc(50% - 150px);
  top: calc(50% - 200px);
}

.ais-InstantSearch__root{
  width: 100%;
}
